<template>
  <el-select
    v-model="contactModel"
    @change="contactChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.CONTACTS')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getContacts"
    :loading="loading"
  >
  <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.ALL_CONTACTS')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="(contact, key) in contacts"
      :key="key"
      :value="contact.id"
      :label="`${contact.firstname && contact.lastname ? contact.firstname+' '+contact.lastname : contact.company_name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "contact-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: false,
      description: "Allow tag creation",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    contact: {
      type: String,
      default: null,
      description: "Contact id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      contactModel: this.contact,
      contacts: {},
    };
  },

  setup() {},

  created() {
    this.getContacts(null, this.contact);
  },

  methods: {
    async getContacts(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "firstname",
          filter: {
            ...(query ? { search: query } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        await this.$store.dispatch("contacts/list", params);
        const contactsArr = await this.$store.getters["contacts/list"];
        this.contacts = {};
        contactsArr.forEach((contact) => {
          this.contacts[contact.id] = contact;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    contactChanged(contact) {
      this.$emit("contactChanged", contact);
    },
  },

  watch: {
    contact(contact) {
      if (contact) {
        this.contactModel = contact;
        if (contact !== this.contactModel) {
          this.getContacts(null, contact);
        }
      } else {
        this.contactModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getContacts();
    },
  },
};
</script>
