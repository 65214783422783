var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.communication.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.communication.id || !!_vm.property || !!_vm.contact},on:{"organizationChanged":(organizationId, organization) => {
              _vm.communication.organization.id = organizationId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMUNICATIONS.COMMUNICATION_TYPE')} (*)`,"placeholder":_vm.$t('COMMUNICATIONS.COMMUNICATION_TYPE')}},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"clearable":true,"placeholder":_vm.$t('COMMUNICATIONS.COMMUNICATION_TYPE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.communication.communication_type),callback:function ($$v) {_vm.$set(_vm.communication, "communication_type", $$v)},expression:"communication.communication_type"}},_vm._l((_vm.COMMUNICATIONS_TYPES),function(item,key){return _c('el-option',{key:key,staticClass:"select-primary",attrs:{"label":_vm.$t(`COMMUNICATIONS.COMMUNICATIONS_TYPE_${item}`),"value":item}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.communication_type}})],1),_c('div',{staticClass:"form-wrapper"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CONTACTS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMUNICATIONS.CONTACT')} (*)`,"placeholder":_vm.$t('COMMUNICATIONS.CONTACT')}},[_c('contact-selector',{attrs:{"contact":_vm.communication.contact.id,"filterable":true,"showAll":false,"filterOrganization":_vm.communication.organization.id},on:{"contactChanged":(contactId, contact) => {
              _vm.communication.contact.id = contactId;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.contact}})],1),_c('div',{staticClass:"form-wrapper"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PROPERTIES))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PROPERTIES')}`,"placeholder":_vm.$t('COMMON.PROPERTIES')}},[_c('properties-selector',{attrs:{"properties":_vm.communication.properties,"filterable":true,"showAll":false,"multiple":true,"filterOwner":_vm.communication.contact.id},on:{"propertiesChanged":(properties) => {
            _vm.communication.properties = properties;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.property}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":_vm.$t('COMMUNICATIONS.EXCERPT'),"placeholder":_vm.$t('COMMUNICATIONS.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.communication.excerpt),callback:function ($$v) {_vm.$set(_vm.communication, "excerpt", $$v)},expression:"communication.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMUNICATIONS.TRAKING_NUMBER')}`,"placeholder":_vm.$t('COMMUNICATIONS.TRAKING_NUMBER')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.communication.tracking_number),callback:function ($$v) {_vm.$set(_vm.communication, "tracking_number", $$v)},expression:"communication.tracking_number"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tracking_number}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMUNICATIONS.DATE')}`,"placeholder":_vm.$t('COMMUNICATIONS.DATE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.communication.date),callback:function ($$v) {_vm.$set(_vm.communication, "date", $$v)},expression:"communication.date"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"placeholder":_vm.$t('COMMUNICATIONS.DATE'),"config":{
            allowInput: true,
            enableTime: false,
            type: 'date',
            maxDate: 'today',
            locale: _vm.$flatPickrLocale(),
            disable: [
              function (date) {
                return date.getDay() === 0;
              },
            ],
          }},on:{"on-change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.communication.date),callback:function ($$v) {_vm.$set(_vm.communication, "date", $$v)},expression:"communication.date"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.date}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('attachements-selector',{attrs:{"label":_vm.$t('COMMUNICATIONS.ATTACHEMENTS'),"defaultAttachements":_vm.communication.attachements,"ressource_name":"communications","ressource_id":_vm.communication.id ? _vm.communication.id : 0,"field":"attachements"},on:{"attachementsChanged":(attachements_urls) => {
        _vm.communication.attachements = attachements_urls;
        _vm.onFormChanged();
      }}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.communication.id ? _vm.$t("COMMUNICATIONS.EDIT_COMMUNICATION") : _vm.$t("COMMUNICATIONS.ADD_COMMUNICATION"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }