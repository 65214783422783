<template>
  <div class="">
    <el-select
      v-model="propertiesModel"
      @change="propertyChanged"
      autocomplete="new-password"
      :placeholder="placeholder ? placeholder : $t('COMMON.PROPERTIES')"
      :filterable="filterable"
      :multiple="true"
      :disabled="disabled"
      remote
      :remote-method="getProperties"
      :loading="loading"
      collapse-tags
    >
      <el-option
        v-if="showAll"
        :value="null"
        :label="placeholder ? placeholder : $t('COMMON.PROPERTIES')"
      >
      </el-option>
      <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
      </el-option>
      <el-option
        v-for="(property, key) in propertyList"
        :key="key"
        :value="property.id"
        :label="`${property.property_number}`"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { difference } from "lodash";

export default {
  name: "properties-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    properties: {
      type: Array,
      default: () => [],
      description: "Warehouse id",
    },
    filterIdsNotIn: {
      type: Array,
      default: () => [],
      description: "Warehouse not in list id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterOwner: {
      type: String,
      default: null,
      description: "Contact id",
    },
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
  },

  data() {
    return {
      propertiesModel: [...this.properties].map((item) => item.id),
      propertyList: [],
      loading: false,
    };
  },

  setup() {},

  created() {
    this.getProperties(null, this.propety);
  },

  methods: {
    async getProperties(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { search: query } : {}),
            ...(ids?.length ? { ids: ids.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        if (this.filterOwner) {
          params = {
            ...params,
            filter: { ...params.filter, owner: this.filterOwner },
          };
        }

        if (this.filterIdsNotIn.length > 0) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              idsNotIn: this.filterIdsNotIn,
            },
          };
        }
        await this.$store.dispatch("properties/list", params);
        const propertyArr = await this.$store.getters["properties/list"];
        this.propertyList = {};
        propertyArr.forEach((property) => {
          this.propertyList[property.id] = property;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    propertyChanged(properties) {
      this.$emit(
        "propertiesChanged",
        [...properties].map((item) => ({ type: "properties", id: item }))
      );
    },
  },

  watch: {
    properties(properties) {
      if (properties?.length) {
        const propertiesData = properties.map((item) => item.id);
        if (difference(propertiesData, this.locationsModel).length !== 0) {
          this.propertiesModel = propertiesData;
        }
      } else {
        this.propertiesModel = [];
      }
    },
    filterOrganization(filterOrganization) {
      this.getProperties();
    },
    filterOwner(filterOwner) {
      this.getProperties();
    },
    filterIdsNotIn(filterIdsNotIn) {
      this.getProperties();
    },
  },
};
</script>
