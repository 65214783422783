<template>
  <div class="elite-tabs-wrapper-content">
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="!loading ? properties : []"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>
            <el-table-column
              :label="$t('PROPERTIES.PROPERTY_NUMBER')"
              prop="property_number"
              sortable="custom"
              min-width="120"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.property_number ?? "" }}
                </span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMON.ADDRESS')" min-width="220">
              <template v-slot="{ row }">
                <span>
                  {{ row?.address ?? "" }}, {{ row?.city ?? "" }},
                  {{ row?.state ?? "" }}, {{ row?.zipcode ?? "" }}
                </span>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('PROPERTIES.BATCH_NUMBERS')"
              prop="batch_numbers"
              min-width="150"
            >
              <template v-slot="{ row }">
                <span v-for="(item, idx) in row.batch_numbers" :key="idx">
                  <template>
                    {{ `${item.value}` }}
                    {{ row.batch_numbers.length - 1 > idx ? ", " : " " }}
                  </template>
                </span>
              </template>
            </el-table-column>

            <el-table-column min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_PROPERTIES)"
                >
                  <a
                    type="text"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <router-link :to="$objectViewRoute(row)">
                      <i class="fal fa-expand-alt"></i>
                    </router-link>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import Users from "@/components/Users.vue";
import { PROPERTY_STATUS } from "@/constants/properties";
import defaultProperty from "../../../AuctionsModule/PropertyManagement/defaultProperty";

export default {
  name: "contact-property-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    LocationsSelector,
    Users,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterOwner: {
      type: String,
      default: null,
      description: "Contact id",
    },
  },

  data() {
    const propertyData = this.$fillUserOrganizationData(defaultProperty);
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      PROPERTY_STATUS,
      propertyStatus: null,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      properties: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: propertyData.allowedLocations,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    propertyStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterOwner: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations,updatedBy,createdBy",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }

        if (this.filterOwner) {
          params = {
            ...params,
            filter: { ...params.filter, owner: this.filterOwner },
          };
        }

        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.propertyStatus === false || this.propertyStatus === true) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              active: this.propertyStatus,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("properties/list", params);
        this.properties = this.$store.getters["properties/list"];
        this.total = this.$store.getters["properties/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteProperty(property) {
      this.$emit("onDeleteProperty", property);
    },

    viewProperty(property) {
      this.$objectViewRoute(property);
    },

    editProperty(property) {
      this.$emit("onEditProperty", property);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
