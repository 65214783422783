export default {
  type: "communications",
  communication_type: null,
  tracking_number: "",
  date: null,
  excerpt: "",
  attachements: null,
  relationshipNames: ["organization", "contact", "properties"],
  organization: {
    type: "organizations",
    id: null,
  },
  contact: {
    type: "contacts",
    id: null,
  },
  properties: [],
};
